import React from 'react';
import { Button, Grid, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '250px',
    height: '240px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    textAlign: 'justify'
  },
  itemButton: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  }
}));


export default (props) => {
  
  const classes = useStyles();

  const { modalOpen, setModalOpen, horasCancelamento } = props;

  return <Modal
    open={modalOpen}
    onClose={() => setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Grid container>
        <Grid item xs={12} className={classes.item}>
          {`É permitido o cancelamento desta reserva caso a solicitação seja realizada até ${horasCancelamento} horas antes do cancelamento.`}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          {"Caso seja solicitado o cancelamento dentro deste prazo, o valor cobrado da reserva será estornado.*"}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          {"Após o prazo limite, o valor da reserva não será devolvido."}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          {"*A taxa de conveniencia não é estornada."}
        </Grid>
        <Grid item xs={12} className={classes.itemButton}>
          <Button 
            onClick={() => setModalOpen(false)}
            color="primary"
            variant="outlined"
            size="small"
          >
            {"FECHAR"}
          </Button>
        </Grid>
      </Grid>
    </div>
  </Modal>
}