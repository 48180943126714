import React, { createContext, useEffect, useState } from 'react'
import api from '../../services/apiCliente'

export const ClienteContext = createContext({logged: false})

export const ClienteProvider = ({ children }) => {

  const [auth, setAuth] = useState({ logged: false })

  const login = (cliente) => {
    localStorage['cliente'] = JSON.stringify(cliente);
    setAuth({
      cliente,
      logged: true
    });
  }

  const logout = () => {    
    delete localStorage.cliente;
    setAuth({
      cliente: undefined,
      logged: false
    });
  }

  useEffect(() => {
    const stringCliente = localStorage['cliente']
    if (stringCliente) {
      const dadosCliente = JSON.parse(stringCliente);
      setAuth({
        cliente: dadosCliente,
        logged: true
      });
      api.setToken(dadosCliente.accessToken)
    }

  }, [])

  return <ClienteContext.Provider value={{auth, login, logout}}>
    {children}
  </ClienteContext.Provider>
}