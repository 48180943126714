import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { getTransferencias } from './SaldosApi';
import Fetching from '../utils/Fetching';
import { Grid, isWidthDown, isWidthUp, withWidth } from '@material-ui/core';
import moment from 'moment';
import currencyFormatter from '../utils/currencyFormatter';

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%"
  },
  row: {
    padding: theme.spacing(1)
  },
  rowOdd: { 
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(1)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 12
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  value: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold'
  }
}));

const getStatus = (status) => {
  if (status === 'pending_transfer') return 'Pendente';
  if (status === 'transferred') return 'Concluída';
  if (status === 'failed') return 'Falha';
  if (status === 'processing') return 'Em Processamento';
  if (status === 'canceled') return 'Cancelada';
  return '';
}

const Transferencias = (props) => {

  const { setMensagem, width } = props;

  const [rows, setRows] = useState([]);
  const [fetching, setFetching] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const fetchTransferencias = async() => {

    setFetching(true);

    const response = await getTransferencias(5, 0);
    if (response.ok) {
      setRows(response.data);
    } else {
      setMensagem("Erro ao buscar os antecipações. Contate o Administrador");
      setRows([]);
    }

    setFetching(false);
  }

  fetchTransferencias();

  }, [setMensagem]);

  const ResponsiveValue = ({ label, value }) => <div className={classes.value}>
    {isWidthDown('sm', width) && <span className={classes.label}>{label}</span>}
    {value}
  </div>

  return rows && rows.length > 0 && <Fetching isFetching={fetching}>
      <Grid container className={classes.titleContainer}>
        <span className={classes.title}>{"Últimas Transferências"}</span>
      </Grid>
      <Paper className={classes.paper}>
        {isWidthUp('md', width) && <Grid container className={classes.row}>
          <Grid item md={3} className={classes.item}>
            <span className={classes.label}>{"Data"}</span>
          </Grid>
          <Grid item md={3} className={classes.item}>
            <span className={classes.label}>{"Valor"}</span>
          </Grid>
          <Grid item md={3} className={classes.item}>
            <span className={classes.label}>{"Taxa"}</span>
          </Grid>
          <Grid item md={3} className={classes.item}>
            <span className={classes.label}>{"Status"}</span>
          </Grid>
        </Grid>}
        {rows.map((row, index) => (
          <Grid container key={row.id} className={index % 2 === 0 ? classes.rowOdd : classes.row}>
            <Grid item xs={12} sm={6} md={3} className={classes.item}>
              <ResponsiveValue 
                label="Data: "
                value={row.funding_date ? moment(row.funding_date).format('DD/MM/YYYY HH:mm') : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.item}>
              <ResponsiveValue 
                label="Valor: "
                value={currencyFormatter(row.amount / 100)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.item}>
              <ResponsiveValue 
                label="Taxa: "
                value={currencyFormatter(row.fee / 100)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.item}>
              <ResponsiveValue 
                label="Status: "
                value={getStatus(row.status)}
              />
            </Grid>
          </Grid>
        ))}
      </Paper>
    </Fetching>
}

export default withWidth()(Transferencias)