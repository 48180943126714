import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Fetching from '../utils/Fetching';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, isWidthDown, isWidthUp, withWidth } from '@material-ui/core';
import currencyFormatter from '../utils/currencyFormatter';
import { getAgendamentos } from './AgendamentosApi';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Snackbar from '../../Components/Common/Snackbar';

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%"
  },
  row: {
    padding: theme.spacing(1)
  },
  rowOdd: { 
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(1)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 12
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  value: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  arrowsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    borderTop: `2px solid ${theme.palette.action.hover}`,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterItem: {
    padding: theme.spacing(1)
  },
  itensContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
}));

const getTipoOcupacao = (ocupacao) => {
  if (ocupacao.tipo === 'INDISPONIVEL') {
    return "Outra Ocupação";
  }
  if (ocupacao.tipo === 'AGENDAMENTO') {
    return "Agendamento Online";
  }
  if (ocupacao.tipo === 'RESERVA_MANUAL') {
    return "Reserva Manual";
  }
  return ocupacao.tipo;

}

const Agendamentos = (props) => {

  const { width } = props;

  const [rows, setRows] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [mensagem, setMensagem] = useState();
  
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [tipos, setTipos] = useState({
    agendamentos: true,
    reservaManual: true,
    ocupacoes: false
  });

  const pageCount = 10;
  const [filter, setFilter] = useState({
    tipos: {
      agendamentos: true,
      reservaManual: true,
      ocupacoes: false
    },
    page: 0
  });

  const handleClickTipo = (event) => {
    setTipos({ ...tipos, [event.target.name]: event.target.checked });
  };


  const classes = useStyles();

  const handleBackButtonClick = (event) => {
    setFilter({
      ...filter,
      page: filter.page - 1
    });
  };

  const handleNextButtonClick = (event) => {
    setFilter({
      ...filter,
      page: filter.page + 1
    });
  };

  useEffect(() => {
    const fetchAgendamentos = async() => {

    setFetching(true);

    let q = `$limit=${pageCount}&$skip=${pageCount * filter.page}`;
    if (filter.tipos.agendamentos) {
      q = q + '&tipo[$in][]=AGENDAMENTO'
    }
    if (filter.tipos.reservaManual) {
      q = q + '&tipo[$in][]=RESERVA_MANUAL'
    }
    if (filter.tipos.ocupacoes) {
      q = q + '&tipo[$in][]=INDISPONIVEL'
    }

    if (filter.dataInicio) {
      q = q + `&dataHoraDe=${moment(filter.dataInicio).startOf('day').toISOString()}`
    }
    if (filter.dataFim) {
      q = q + `&dataHoraAte=${moment(filter.dataFim).endOf('day').toISOString()}`
    }

    const response = await getAgendamentos(q);

    if (response.ok) {
      setRows(response.data);
    } else {
      setMensagem("Erro ao buscar os recebíveis. Contate o Administrador");
      setRows([]);
    }

    setFetching(false);
  }

  fetchAgendamentos();

  }, [filter]);

  const ResponsiveValue = ({ label, value }) => <div className={classes.value}>
    {isWidthDown('sm', width) && <span className={classes.label}>{label}</span>}
    {value}
  </div>

  const { agendamentos, reservaManual, ocupacoes } = tipos;

  return <>
    <Paper>
      <Grid container className={classes.center}>
        <Grid item xs={12} className={classes.titleContainer}>
          <span className={classes.title}>{"Agendamentos"}</span>
        </Grid>
        <Grid item className={classes.center}>
          <FormControl component="fieldset">
            <FormGroup row>
              <Grid container className={classes.itensContainer}>
                <Grid item className={classes.filterItem}>
                  <FormControlLabel
                    control={<Checkbox checked={agendamentos} onChange={handleClickTipo} name="agendamentos" />}
                    label="Agendamentos"
                  />
                </Grid>
                <Grid item className={classes.filterItem}>
                  <FormControlLabel
                    control={<Checkbox checked={reservaManual} onChange={handleClickTipo} name="reservaManual" />}
                    label="Reservas Manuais"
                  />
                </Grid>
                <Grid item className={classes.filterItem}>
                  <FormControlLabel
                    control={<Checkbox checked={ocupacoes} onChange={handleClickTipo} name="ocupacoes" />}
                    label="Outras Ocupações"                    
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container className={classes.center}>
            <Grid item className={classes.filterItem}>
              <DatePicker
                value={dataInicio}
                onChange={(date) => setDataInicio(date)}                
                label="Data Início"
                clearable
                clearLabel="Limpar"
                cancelLabel="Cancelar"
                autoOk
                format="DD/MM/YYYY"
              />
            </Grid>
            <Grid item className={classes.filterItem}>
              <DatePicker
                value={dataFim}
                onChange={(date) => setDataFim(date)}
                label="Data Fim"
                clearable
                clearLabel="Limpar"
                cancelLabel="Cancelar"
                autoOk
                format="DD/MM/YYYY"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setFilter({
                tipos,
                dataInicio,
                dataFim,
                page: 0
              })
            }}
          >
            {"BUSCAR"}
          </Button>
        </Grid>
      </Grid>

      <Fetching isFetching={fetching}>
        <Paper className={classes.paper}>
          {isWidthUp('md', width) && <Grid container className={classes.row}>
            <Grid item md={2} className={classes.item}>
              <span className={classes.label}>{"Data"}</span>
            </Grid>
            <Grid item md={2} className={classes.item}>
              <span className={classes.label}>{"Quadra"}</span>
            </Grid>
            <Grid item md={2} className={classes.item}>
              <span className={classes.label}>{"Tipo"}</span>
            </Grid>
            <Grid item md={2} className={classes.item}>
              <span className={classes.label}>{"Descrição"}</span>
            </Grid>
            <Grid item md={2} className={classes.item}>
              <span className={classes.label}>{"Valor"}</span>
            </Grid>
            <Grid item md={2} className={classes.item}>
              <span className={classes.label}>{"Status"}</span>
            </Grid>    
          </Grid>}
          {rows.map((row, index) => (
            <Grid container key={row.id} className={index % 2 === 0 ? classes.rowOdd : classes.row}>
              <Grid item xs={12} sm={6} md={2} className={classes.item}>
                <ResponsiveValue 
                  label="Data: "
                  value={moment(row.dataHora).format('DD/MM/YYYY HH:mm')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.item}>
                <ResponsiveValue 
                  label="Espaço: "
                  value={row['espaco.nome']}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.item}>
                <ResponsiveValue 
                  label="Tipo: "
                  value={getTipoOcupacao(row)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.item}>
                <ResponsiveValue 
                  label="Descrição: "
                  value={row.descricao}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.item}>
                <ResponsiveValue 
                  label="Valor: "
                  value={row.valor && currencyFormatter(Number(row.valor))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} className={classes.item}>
                <ResponsiveValue 
                  label="Status: "
                  value={row.status}
                />
              </Grid>
            </Grid>
          ))}
          <Grid container className={classes.arrowsContainer}>           
            <IconButton onClick={handleBackButtonClick} disabled={filter.page === 0}>
              <KeyboardArrowLeft />
            </IconButton>
            <span>{filter.page + 1}</span>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={rows.length < pageCount}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
        </Paper>
      </Fetching>
    </Paper>
  <Snackbar message={mensagem} onClose={() => setMensagem(undefined)} />
</>
}

export default withWidth()(Agendamentos)