import React, { useEffect, useState } from 'react';
import { WeekView, DayView } from '@devexpress/dx-react-scheduler-material-ui';
import { isWidthUp, makeStyles, withWidth } from '@material-ui/core';
import moment from 'moment'
import AgendaSkeleton from './AgendaSkeleton';
import Fetching from '../utils/Fetching';
import { fetchAgendas, fetchDiasOcupados, fetchHorariosSemanais, fetchOcupacoesAgenda } from './AgendaApi';
  
const useStyles = makeStyles(theme => ({
  espacoAgenda: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  agendaTotalmenteDisponivel: {
    backgroundColor: theme.palette.light,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontSize: 8,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  agendaParcialmenteDisponivel: {
    backgroundColor: theme.palette.light,
    height: '25%',
    width: '100%'
  },
  agendaParcialmenteIndisponivel: {
    backgroundColor: 'transparent',
    height: '25%',
    width: '100%'
  },
}));

const isDisponivel = (dateTimeCell, agendas) => {
  return agendas && agendas.find(a => {

    if (dateTimeCell.isoWeekday() !== a.diaSemana) return false;

    const horaInicio = moment(a.horaInicio);
    const horaFim = moment(a.horaFim);

    const dateTimeCellNoDia = moment(horaInicio).hour(dateTimeCell.hour()).minute(dateTimeCell.minute())

    return dateTimeCellNoDia.isSameOrAfter(horaInicio) && dateTimeCellNoDia.isBefore(horaFim);

  })
}

const calculateStartHour = (agendas) => {
  if (!agendas || agendas.length === 0) {
    return 8;
  }
  const minHour = Math.min(...agendas.map(a => moment(a.horaInicio).hour()));
  return minHour;
}

const EspacoDisponivel = (props) => {
  const classes = useStyles();
  const isDisponivel1Parte = isDisponivel(moment(props.startDate), props.agendas);
  const isDisponivel2Parte = isDisponivel(moment(props.startDate).add(15, 'minutes'), props.agendas);
  const isDisponivel3Parte = isDisponivel(moment(props.startDate).add(30, 'minutes'), props.agendas);
  const isDisponivel4Parte = isDisponivel(moment(props.startDate).add(45, 'minutes'), props.agendas);
  const horarioTotalmenteDisponivel = isDisponivel1Parte && isDisponivel2Parte && isDisponivel3Parte && isDisponivel4Parte

  return <div className={classes.espacoAgenda}>
    {horarioTotalmenteDisponivel && <div className={classes.agendaTotalmenteDisponivel}>
      {"DISPONÍVEL"}
    </div>}
    {!horarioTotalmenteDisponivel && <>
      <div className={isDisponivel1Parte ? classes.agendaParcialmenteDisponivel : classes.agendaParcialmenteIndisponivel} />
      <div className={isDisponivel2Parte ? classes.agendaParcialmenteDisponivel : classes.agendaParcialmenteIndisponivel} />
      <div className={isDisponivel3Parte ? classes.agendaParcialmenteDisponivel : classes.agendaParcialmenteIndisponivel} />
      <div className={isDisponivel4Parte ? classes.agendaParcialmenteDisponivel : classes.agendaParcialmenteIndisponivel} />
    </>}
  </div>
}

const TimeTableCellWeek = (props) => {
  return <WeekView.TimeTableCell {...props} >
      <EspacoDisponivel {...props} />
    </WeekView.TimeTableCell>
};

const TimeTableCellDay = (props) => {
  return <DayView.TimeTableCell {...props}>
      <EspacoDisponivel {...props} />
    </DayView.TimeTableCell>
};

const DayOrWeekView = (props) => {

  const { espacoSelecionado, setErro, fetchingEspacos } = props;

  const [agendas, setAgendas] = useState([]);
  const [ocupacoesAgenda, setOcupacoesAgenda] = useState([]);
  const [horariosSemanais, setHorariosSemanais] = useState([]);
  const [diasOcupados, setDiasOcupados] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [versionOcupacoes, setVersionOcupacoes] = useState(0);
  const [versionHorariosSemanais, setVersionHorariosSemanais] = useState(0);
  const [versionDiasOcupados, setVersionDiasOcupados] = useState(0);

  const [fetchingAgendas, setFetchingAgendas] = useState(false);
  const [fetchingOcupacoes, setFetchingOcupacoes] = useState(false);
  const [fetchingHorariosSemanais, setFetchingHorariosSemanais] = useState(false);
  const [fetchingDiasOcupados, setFetchingDiasOcupados] = useState(false);
  

  useEffect(() => {
    const getAgendas = async() => {
      setFetchingAgendas(true);
      const response = await fetchAgendas(espacoSelecionado);
      if (response.ok) {
        setAgendas(response.data);
      } else {
        setErro("Erro ao buscar os horários da quadra. Contate o Administrador");
        setAgendas([]);
      }
      setFetchingAgendas(false);
    }
    getAgendas();
  }, [espacoSelecionado, setErro]);

  useEffect(() => {
    const getHorariosSemanais = async() => {
      setFetchingHorariosSemanais(true);
      const response = await fetchHorariosSemanais(espacoSelecionado);
      if (response.ok) {
        setHorariosSemanais(response.data);
      } else {
        setErro("Erro ao buscar os horários semanais. Contate o Administrador");
        setHorariosSemanais([]);
      }
      setFetchingHorariosSemanais(false);
    }
    getHorariosSemanais();
  }, [espacoSelecionado, setErro, versionHorariosSemanais]);

  useEffect(() => {
    const getDiasOcupados = async() => {
      setFetchingDiasOcupados(true);
      const response = await fetchDiasOcupados(espacoSelecionado);
      if (response.ok) {
        setDiasOcupados(response.data);
      } else {
        setErro("Erro ao buscar os dias ocupados. Contate o Administrador");
        setDiasOcupados([]);
      }
      setFetchingDiasOcupados(false);
    }
    getDiasOcupados();
  }, [espacoSelecionado, setErro, versionDiasOcupados]);

  useEffect(() => {
    const getOcupacoesAgenda = async () => {
      setFetchingOcupacoes(true);
      const response = await fetchOcupacoesAgenda(espacoSelecionado);
      if (response.ok) {
        setOcupacoesAgenda(response.data);
      } else {
        setErro("Erro ao buscar os agendamentos. Contate o Administrador");
        setOcupacoesAgenda([]);
      }
      setFetchingOcupacoes(false);
    }
    getOcupacoesAgenda();
  }, [espacoSelecionado, setErro, versionOcupacoes]);

  const isFetchingAgenda = fetchingAgendas || fetchingOcupacoes || fetchingHorariosSemanais || fetchingDiasOcupados;
  const startHour = calculateStartHour(agendas);

  const View = () => {

    const commonProps = {
      startDayHour: startHour,
      cellDuration: 60
    }

    if (isWidthUp('md', props.width)) {
      return <WeekView 
        timeTableCellComponent={(componentProps) => TimeTableCellWeek({ agendas, ...componentProps })}
        timeScaleLabelComponent={(componentProps) => <WeekView.TimeScaleLabel {...componentProps} />}
        timeScaleTickCellComponent={(componentProps) => <WeekView.TimeScaleTickCell {...componentProps} />}
        {...commonProps} 
      />

    } else {
      return <DayView 
        timeTableCellComponent={(componentProps) => TimeTableCellDay({ agendas, ...componentProps })}
        timeScaleLabelComponent={(componentProps) => <DayView.TimeScaleLabel {...componentProps} />}
        timeScaleTickCellComponent={(componentProps) => <DayView.TimeScaleTickCell {...componentProps} />}
        {...commonProps} 
      />
    }
  }

  return <Fetching isFetching={fetchingEspacos || isFetchingAgenda}>
    <AgendaSkeleton 
      versionOcupacoes={versionOcupacoes}
      setVersionOcupacoes={setVersionOcupacoes}
      ocupacoesAgenda={ocupacoesAgenda} 
      versionHorariosSemanais={versionHorariosSemanais}
      setVersionHorariosSemanais={setVersionHorariosSemanais}
      horariosSemanais={horariosSemanais} 
      versionDiasOcupados={versionDiasOcupados}
      setVersionDiasOcupados={setVersionDiasOcupados}
      diasOcupados={diasOcupados} 
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      {...props}
    >
      <View/>
    </AgendaSkeleton>
  </Fetching>
}

export default withWidth()(DayOrWeekView);