import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, isWidthDown, makeStyles, Paper, withWidth } from "@material-ui/core";
import Fetching from '../utils/Fetching';
import { getEmpresa, updateEmpresa } from './ConfiguracoesApi';
import Snackbar from '../../Components/Common/Snackbar';
import { AuthContext } from '../../Components/Login/Auth';
import { Field, Form } from 'react-final-form';
import TextField from '../../Components/Fields/TextField'
import CPFCNPJField from '../../Components/Fields/CPFCNPJField'
import TelephoneField from '../../Components/Fields/TelephoneField'
import ImagePicker from '../../Components/Common/ImagePicker';
import withoutLogo from '../../img/no_logo.png';
import config from '../../config';
import { defaultPrimaryColor, defaultSecondaryColor } from '../../Theme';
import ColorPicker from './ColorPicker';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  configuracoes: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(2)
  },
  titulo: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  helper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    fontSize: 10,
    color: theme.palette.secondary.main
  },
  link: {
    marginRight: 3,
    marginLeft: 3
  },
  field: {
    minWidth: 300
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  buttonSalvar: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.white
  },
  buttonVoltar: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  colorPicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}));

export default withWidth()((props) => {

  const classes = useStyles();
  const { history, width } = props;

  const authContext = useContext(AuthContext);
  const empresaId = authContext.user.id;
  const [dadosEmpresa, setDadosEmpresa] = useState({});
  const [updates, setUpdates] = useState([]);

  const [fetching, setFetching] = useState(false);
  const [mensagem, setMensagem] = useState();

  const { bucketURL } = config;

  const googleMapsHint = isWidthDown('sm', width) 
    ? `${bucketURL}/maps-link-xs.gif` 
    : `${bucketURL}/maps-link.gif`;

  const [primaryColor, setPrimaryColor] = useState();
  const [secondaryColor, setSecondaryColor] = useState();

  const limpaMensagem = () => {
    setMensagem(undefined);
  }
 
  useEffect(() => {
    const fetchDadosEmpresa = async() => {

      setFetching(true);

      const response = await getEmpresa(empresaId);
      if (response.ok) {
        const { data } = response;
        setDadosEmpresa(data);
        setPrimaryColor(data.primaryColor || defaultPrimaryColor);
        setSecondaryColor(data.secondaryColor || defaultSecondaryColor);
      } else {
        setMensagem("Erro ao buscar dados da academia. Contate o Administrador");
        setDadosEmpresa({});
      }

      setFetching(false);
    }

    fetchDadosEmpresa();
  }, [empresaId, updates]);


  const removeMask = (dadosRecebimento) => {
    const { document_number, ...rest } = dadosRecebimento
    return {
      ...(document_number ? {
        document_number: document_number.replace(/\D/g,'')
      } : {})
      ,
      ...rest
    }
  }

  const onSubmit = (values) => {

    updateEmpresa(empresaId, {
      nome: values.nome,
      endereco: values.endereco || "",
      linkMap: values.linkMap || "",
      telefone: values.telefone || "",
      file: values.logoUrl && values.logoUrl.length > 0 ? values.logoUrl[0].file : undefined,
      primaryColor,
      secondaryColor,
      antecedenciaCancelamento: values.antecedenciaCancelamento,
      dadosRecebimento: removeMask(values.dadosRecebimento),
      tempoCancelamentoPix: values.tempoCancelamentoPix,
      tempoAntecedenciaReserva: values.tempoAntecedenciaReserva,
    }).then(response => {
      if (response.ok) {
        setUpdates([...updates, response.data.id]);
        setMensagem("Dados atualizados!");
      } else {
        setMensagem(`Erro ao atualizar dados da academia: ${response.data.message}`);
      }
    });
  }


  const validateFields = values => {
    const erros = {};
    if (!values.nome) {
      erros.nome = "Campo obrigatório"
    }

    if (!values.antecedenciaCancelamento) {
      erros.antecedenciaCancelamento = "Campo obrigatório"
    }

    const validateDadosBancarios = values.dadosRecebimento 
      && Object.keys(values.dadosRecebimento).some(k => values.dadosRecebimento[k]);

    if (validateDadosBancarios) {
      erros.dadosRecebimento = {};
      if (!values.dadosRecebimento.bank_code) {
        erros.dadosRecebimento.bank_code = "Campo obrigatório"
      }
      if (!values.dadosRecebimento.agencia) {
        erros.dadosRecebimento.agencia = "Campo obrigatório"
      }
      if (!values.dadosRecebimento.conta) {
        erros.dadosRecebimento.conta = "Campo obrigatório"
      }
      if (!values.dadosRecebimento.conta_dv) {
        erros.dadosRecebimento.conta_dv = "Campo obrigatório"
      }
      if (!values.dadosRecebimento.document_number) {
        erros.dadosRecebimento.document_number = "Campo obrigatório"
      }
      if (!values.dadosRecebimento.legal_name) {
        erros.dadosRecebimento.legal_name = "Campo obrigatório"
      }
    }

    return erros;
  }

  return <>
    <Paper>
      <Fetching isFetching={fetching} >
        <Form
          onSubmit={onSubmit}
          initialValues={dadosEmpresa}
          validate={validateFields}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>   
              <Grid container className={classes.form}>    
                <Grid item xs={12} lg={2}/> 
                <Grid item xs={12} md={6} lg={4} className={classes.configuracoes}>
                  <Grid container>
                    <Grid item xs={12} className={classes.row}>
                      <span className={classes.titulo}>{"Identidade Visual"}</span>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="nome"
                        label="Nome da Academia"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        name="logoUrl"
                        defaultImage={withoutLogo}
                        size={300}
                        component={ImagePicker}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.helper}>
                      {"O logo deve ser QUADRADO para exibição no App"}
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <ColorPicker
                        color={primaryColor}
                        className={classes.colorPicker}
                        onChangeComplete={(color) => setPrimaryColor(color.hex)}
                      />
                      <ColorPicker
                        color={secondaryColor}
                        className={classes.colorPicker}
                        onChangeComplete={(color) => setSecondaryColor(color.hex)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.configuracoes}>
                  <Grid container>
                    <Grid item xs={12} className={classes.row}>
                      <span className={classes.titulo}>{"Localização e Contato"}</span>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="endereco"
                        label="Endereço"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.helper}>
                      {"Por ex: Av. Brasil, 99 - São Paulo - SP"}
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="linkMap"
                        label="Link do Google Maps"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.helper}>
                      <a 
                        href={googleMapsHint} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className={classes.link}>
                          {"Veja aqui"}
                      </a> 
                      {"como buscar o link da sua academia"}
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="telefone"
                        label="Telefone de Contato"
                        placeholder="(XX) XXXX-XXXX"
                        component={TelephoneField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="tempoAntecedenciaReserva"
                        label="Antecedência p/ Reserva (minutos)"
                        type="numeric"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="antecedenciaCancelamento"
                        label="Antecedência p/ Cancelamento (horas)"
                        type="numeric"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="tempoCancelamentoPix"
                        label="Tempo expiração PIX (minutos)"
                        type="numeric"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <span className={classes.titulo}>{"Dados Bancários"}</span>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="dadosRecebimento.bank_code"
                        label="Código do Banco"
                        component={TextField}
                        inputProps={{ maxLength: 3 }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="dadosRecebimento.agencia"
                        label="Agência (sem digito)"
                        component={TextField}
                        inputProps={{ maxLength: 4 }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="dadosRecebimento.conta"
                        label="Número da Conta"
                        component={TextField}
                        inputProps={{ maxLength: 13 }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="dadosRecebimento.conta_dv"
                        label="Dígito Conta"
                        component={TextField}
                        inputProps={{ maxLength: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="dadosRecebimento.legal_name"
                        label="Nome / Razão Social Titular"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Field
                        className={classes.field}
                        name="dadosRecebimento.document_number"
                        label="CPF / CNPJ da Conta Bancária"
                        component={CPFCNPJField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                      <Alert severity='warning'>
                        {"Evite a recusa de transferências! Certifique-se que nome e documento são do titular da conta bancária acima."}
                      </Alert>
                    </Grid>
                  </Grid>
                </Grid> 
                <Grid item xs={12} lg={2}/> 
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button 
                    type="submit"
                    className={classes.buttonSalvar}
                    color="primary"
                    variant="contained"
                  >
                    {"SALVAR"}
                  </Button>
                  <Button
                    className={classes.buttonVoltar}
                    color="primary"
                    onClick={() => history.goBack()}
                  >
                    {"VOLTAR"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Fetching>
    </Paper>
    <Snackbar message={mensagem} onClose={limpaMensagem} />
  </>
})