import React, { useState } from 'react';
import { Button, Grid, makeStyles, MenuItem, Modal, Select, TextField } from '@material-ui/core';
import moment from 'moment'
import { addDiaOcupado } from './AgendaApi';
import Fetching from '../utils/Fetching';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '330px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  container: {
    width: '100%'
  },
  labelGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 11,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  selectText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  field: {
    width: '100%',
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  }
}));

export default (props) => {

  const classes = useStyles();

  const [data, setData] = useState(moment().startOf('day'));
  const [espaco, setEspaco] = useState('todos');
  const [descricao, setDescricao] = useState();

  const [fetching, setFetching] = useState(false);

  const { versionDiasOcupados, setVersionDiasOcupados, setErro } = props;

  const handleAddOcupacao = () => {
    setFetching(true);
    addDiaOcupado(espaco, data, descricao).then(response => {
      if (response.ok) {
        setVersionDiasOcupados(versionDiasOcupados + 1);
      } else {
        setErro("Não foi possível adicionar a ocupação. Contate o Administrador");
      }
    })    
  }
  
  return <Modal
    open={props.modalOpen}
    onClose={() => props.setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Fetching isFetching={fetching}> 
        <Grid container className={classes.container}>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"ESPAÇO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <Select
              value={espaco}
              onChange={(event) => setEspaco(event.target.value)}
              className={classes.field}
            >
              {props.espacos && props.espacos.map(q => (
                <MenuItem value={q.id} key={q.id} className={classes.selectText}>
                  {q.nome}
                </MenuItem>))}
              <MenuItem value={'todos'} key={'todos'} className={classes.selectText}>
                {'TODOS'}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"DATA"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <DatePicker
              value={data}
              onChange={setData}
              cancelLabel="Cancelar"
              autoOk
              format="DD/MM/YYYY"
            />
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"DESCRIÇÃO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <TextField
              value={descricao}
              onChange={(event) => setDescricao(event.target.value)}
              className={classes.field}
              placeholder="(opcional)"
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleAddOcupacao}
            >
              {"SALVAR"}
            </Button>
          </Grid>
        </Grid>
      </Fetching>
    </div>
  </Modal>
}