import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Radio } from '@material-ui/core';
import Fetching from '../utils/Fetching';
import { fetchAgendas } from '../agenda/AgendaApi';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { updateAgendas } from './HorariosApi';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const useStyles = makeStyles(theme => ({
  actionArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  actionButton: {
    margin: theme.spacing(2)
  },
  diaContainer: {
    padding: theme.spacing(2),
  },
  labelDia: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2)
  },
  labelAte: {
    fontSize: 11,
    color: theme.palette.primary.main,
  },
  hour: {
    width: 45,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  field: {
    width: 70,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  hourRow: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  buttonRemover: {
    color: theme.palette.red,
    fontSize: 9,
    marginTop: theme.spacing(1)
  },
  buttonAdicionarContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonAdicionar: {
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  labelAplicarPara: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  valueAplicarPara: {
    fontSize: 12,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  itemAplicarPara: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  }
}));

const diasSemana = [
  { weekday: 1, label: 'SEGUNDA-FEIRA' },
  { weekday: 2, label: 'TERÇA-FEIRA' },
  { weekday: 3, label: 'QUARTA-FEIRA' },
  { weekday: 4, label: 'QUINTA-FEIRA' },
  { weekday: 5, label: 'SEXTA-FEIRA' },
  { weekday: 6, label: 'SÁBADO' },
  { weekday: 7, label: 'DOMINGO' },
];

const sortById = (array) => array.sort((a, b) => a.id - b.id)

export default (props) => {
  const classes = useStyles();

  const { espacos, espacoSelecionado, setErro, fetchingEspacos } = props;

  const [fetching, setFetching] = useState(false);
  const [fetchingSave, setFetchingSave] = useState(false);

  const [agendas, setAgendas] = useState([]);

  const [aplicarPara, setAplicarPara] = useState('espaco');

  const objEspacoSelecionado = espacos.find(q => q.id === parseInt(espacoSelecionado));
  const nomeEspaco = objEspacoSelecionado ? objEspacoSelecionado.nome : undefined;
  
  useEffect(() => {
    const fetchAgendasEspaco = async () => {
      setFetching(true);
      const response = await fetchAgendas(espacoSelecionado);
      if (response.ok) {
        setAgendas(sortById(response.data));
      } else {
        setErro("Erro ao buscar os horários. Contate o Administrador");
        setAgendas([]);
      }
      setFetching(false);
    }

    fetchAgendasEspaco();
  }, [espacoSelecionado, setErro]);

  const onChangeHoraInicio = (data, agenda) => {
    agenda.horaInicio = data;
    setAgendas(sortById([
      agenda,
      ...agendas.filter(a => a.id !== agenda.id)
    ]));
  }

  const onChangeHoraFim = (data, agenda) => {
    agenda.horaFim = data;
    setAgendas(sortById([
      agenda,
      ...agendas.filter(a => a.id !== agenda.id)
    ]));
  }

  const onChangeValor = (valor, agenda) => {
    agenda.valor = valor;
    setAgendas(sortById([
      agenda,
      ...agendas.filter(a => a.id !== agenda.id)
    ]));
  }

  const removeAgenda = (id) => {
    setAgendas(agendas.filter(a => a.id !== id));
  }

  const addIntervaloHorario = (diaSemana) => {
    setAgendas([
      ...agendas,
      {
        id: getNewId(),
        diaSemana,
        valor: objEspacoSelecionado.valor,
        horaInicio: moment().startOf('day'),
        horaFim: moment().startOf('day')
      }
    ])
  }

  const getNewId = () => {
    let maiorId = 0;
    agendas.forEach(a => {
      if (a.id > maiorId) {
        maiorId = a.id
      }
    });

    return parseInt(maiorId) + 1
  }

  const handleSave = async () => {
    setFetchingSave(true);

    const data = { agendas };
    if (aplicarPara === 'espaco') {
      data.espacoId = espacoSelecionado;
    }

    const response = await updateAgendas(data);
    if (response.ok) {
      props.setView("agenda");
    } else {
      setErro(response.data?.message || "Erro ao salvar horários. Contate o administrador");
    }      

    setFetchingSave(false);
  }

  return <Fetching isFetching={fetchingEspacos || fetching}>
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center">
        {diasSemana.map(dia => (
          <Grid item key={dia.weekday} className={classes.diaContainer}>
            <div className={classes.labelDia}>{dia.label}</div>
            {agendas
              .filter(ag => ag.diaSemana === dia.weekday)
              .map(agenda => (
                <div key={agenda.id} className={classes.hourRow}>
                  <TimePicker
                    className={classes.hour}
                    value={agenda.horaInicio}
                    onChange={(date) => onChangeHoraInicio(date, agenda)}
                    ampm={false}
                    minutesStep={15}
                    autoOk
                    views={['hours', 'minutes']}
                  />
                  <span className={classes.labelAte}>{"até"}</span>
                  <TimePicker
                    className={classes.hour}
                    value={agenda.horaFim}
                    onChange={(date) => onChangeHoraFim(date, agenda)}
                    ampm={false}
                    minutesStep={15}
                    autoOk
                    views={['hours', 'minutes']}
                    />
                  <CurrencyTextField
                    className={classes.field}
                    variant="standard"
                    value={agenda.valor}
                    currencySymbol="R$"
                    outputFormat="number"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(event, value) => onChangeValor(value, agenda)}>
                  </CurrencyTextField>

                  <Button
                    onClick={() => removeAgenda(agenda.id)}
                    className={classes.buttonRemover}
                  >
                    {"remover"}
                  </Button>
                </div>
              ))}
              <div className={classes.buttonAdicionarContainer}>
                <Button
                  className={classes.buttonAdicionar}
                  onClick={() => addIntervaloHorario(dia.weekday)}
                >
                  {"+ INTERVALO"}
                </Button>
              </div>
          </Grid>            
        ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className={classes.actionArea}>
          <Grid item className={classes.itemAplicarPara}>
            <span className={classes.labelAplicarPara}>{"APLICAR PARA:"}</span>
          </Grid>
          <Grid item className={classes.itemAplicarPara}>
            <Radio 
              size="small"
              color="primary"
              value="espaco"
              checked={aplicarPara === 'espaco'}
              onChange={(event) => setAplicarPara(event.target.value)}
            />
            <span className={classes.valueAplicarPara}>{nomeEspaco}</span>
          </Grid>
          <Grid item className={classes.itemAplicarPara}>
            <Radio 
              size="small"
              color="primary"
              value="todas"
              checked={aplicarPara === 'todas'}
              onChange={(event) => setAplicarPara(event.target.value)}
            />
            <span className={classes.valueAplicarPara}>{"TODAS AS QUADRAS"}</span>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.actionArea}>
        <Fetching isFetching={fetchingSave}>
          <Button 
            className={classes.actionButton}
            color="primary"
            variant="outlined"
            onClick={() => handleSave()}
          >
            {"SALVAR"}
          </Button>
          <Button 
            className={classes.actionButton}
            color="secondary"
            onClick={() => props.setView("agenda")}
          >
            {"VOLTAR"}
          </Button>
        </Fetching>
      </Grid>
    </Grid>
  </Fetching>
}