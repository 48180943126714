import { Grid, TextField as MUITextField } from '@material-ui/core';
import React from 'react'
import { TextField } from '../../Components/Fields';
import { Checkbox as CheckboxField } from '../../Components/Common'
import { Field, Form } from 'react-final-form';
import TelephoneField from '../../Components/Fields/TelephoneField';
import { emailValido } from '../../utils/utils';
import validatePassword from '../login/validationPassword';

export default (props) => {  

  const { 
    classes, 
    handleSaveReserva, 
    componentConsentimento, 
    componentConfirmarReserva,
    logged,
    initialValues
  } = props;

  const validateFields = values => ({
    nome: !values.nome ? "Campo Obrigatório" : undefined,
    telefone: !values.telefone ? "Campo Obrigatório" : undefined,
    email: !values.email ? "Campo Obrigatório" : !emailValido(values.email) ? 'Formato inválido' : undefined,
    password: values.criar_conta ? validatePassword(values.password) : undefined,
    password_confirm: values.criar_conta && (values.password !== values.password_confirm) ? "Senhas diferem" : undefined,
  });

  return <>
    <Form
      onSubmit={handleSaveReserva}
      validate={validateFields}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container className={classes.formContainer}>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="nome"
                className={classes.field}
                label="Seu nome"
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="telefone"
                className={classes.field}
                label="Telefone"
                fullWidth
                component={TelephoneField}
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="email"
                className={classes.field}
                label="E-mail"
                component={TextField}
                disabled={logged}
              />
            </Grid>

            <Grid item xs={12} className={classes.checkBoxContainer}>
              {componentConsentimento}
            </Grid>

            {!logged && <>
              <Grid item xs={12} className={classes.checkBoxContainer}>
                  <Field
                    name="criar_conta"
                    type="checkbox"
                    component={CheckboxField}
                    label={"Quero criar um cadastro com meu e-mail"}
                    labelClass={classes.textoCheckbox}
                  />
              </Grid>

              {values?.criar_conta && <>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <MUITextField 
                    value={values.email}
                    label="Login"
                    style={{width: '100%'}}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Field
                    className={classes.field}
                    component={TextField}
                    label={"Senha"}
                    type='password'
                    name='password'/>
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Field
                    className={classes.field}
                    component={TextField}
                    label={"Confirme a Senha"}
                    type='password'
                    name='password_confirm'/>
                </Grid>
              </>}
            </>}

            <Grid item xs={12} className={classes.submitContainer}>
              {componentConfirmarReserva}
            </Grid>

          </Grid>
        </form>
      )}
    />
  </>;
}