import config from '../config'
import { create } from 'apisauce'

export const restApi = create({
  baseURL: config.baseURL
})

const getApi = () => {

  return {
    setToken: token => restApi.setHeader('Authorization', `Bearer ${token}`),
    ping: () => restApi.get('/ping?$limit=1'),
    register: (data) => restApi.post('/empresas', data),
    login: (credentials) => restApi.post('/authentication', {
      strategy: "empresas",
      ...credentials
    }),
    recoverPassword: data => restApi.post('/solicitacao-recuperar-senha-empresa', data),
    resetPassword: data => restApi.post('/confirmacao-recuperar-senha-empresa', data),
  }
}



export default getApi()
