import { restApi } from '../../services/api';

export const getEmpresa = async (id) => {
  return restApi.get(`empresas/${id}`);
}

export const updateEmpresa = async (id, { dadosRecebimento, ...data }) => {
  const formData = new FormData();
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  for (const key of Object.keys(data)) {
    formData.set(key, data[key]);
  }

  if (dadosRecebimento) {
    for (const key of Object.keys(dadosRecebimento)) {
      formData.set(`dadosRecebimento[${key}]`, dadosRecebimento[key].trim());
    }
  }

  return restApi.patch(`empresas/${id}`, formData, headers);
}
