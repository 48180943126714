import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fetching: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  }
}));

export default ({isFetching, children}) => {
  const classes = useStyles();

  if (isFetching) {
    return <div className={classes.fetching}>
      <CircularProgress/>
    </div>
  } else {
    return children
  }
  
};