import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core';
import { TextField } from '../../Components/Fields';
import { Field, Form } from 'react-final-form';
import api from '../../services/apiCliente';
import Fetching from '../utils/Fetching';


export default (props) => {
  const { classes, setErro, onForgetPasswordSuccess, onBackClick } = props;

  const [fetching, setFetching] = useState(false);

  const validate = values => ({
    email: !values.email ? "Obrigatório" : undefined,
  });

  const onSubmit = (values) => {
    setFetching(true);
    api.forgetPassword(values).then(response => {
      if (response.ok) {
        onForgetPasswordSuccess(values.email);
      } else {
        if (response.status === 422 && response.data.message) {
          setErro(response.data.message);
        }
        else {
        setErro("Erro ao recuperar conta");
        }
      }

      setFetching(false);
    })
  } 

  return <>
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container  className={classes.formContainer}>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                component={TextField}
                name="email"
                label="E-mail"
              />
            </Grid>
            <Grid item xs={12} className={classes.submitContainer}>
              <Fetching isFetching={fetching}>
                <Button
                  variant="outlined"
                  className={classes.backButton}
                  onClick={onBackClick}
                >
                  {"VOLTAR"}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.recoverPasswordButton}
                >
                  {"SOLICITAR CÓDIGO"}
                </Button>
              </Fetching>
            </Grid>
          </Grid>
        </form>)} />
  </>
}