import { restApi } from '../../services/api';

export const fetchEspacos = async () => {
  return restApi.get('espacos');
}

export const addEspaco = async (values) => {
  return restApi.post('espacos', values);
}

export const updateEspaco = async (idEspaco, values) => {
  return restApi.patch(`espacos/${idEspaco}`, values);
}

export const deleteEspaco = async (idEspaco) => {
  return restApi.delete(`espacos/${idEspaco}`);
}