import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { getRecebiveis } from './SaldosApi';
import Fetching from '../utils/Fetching';
import { Grid, isWidthDown, isWidthUp, withWidth } from '@material-ui/core';
import currencyFormatter from '../utils/currencyFormatter';

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%"
  },
  row: {
    padding: theme.spacing(1)
  },
  rowOdd: { 
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(1)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 12
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  value: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  arrowsContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: `2px solid ${theme.palette.action.hover}`,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold'
  },
  tooltipIcon: {
    width: 12,
    height: 12,
    marginLeft: 3
  }
}));

const Recebiveis = (props) => {

  const { setMensagem, width } = props;

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [fetching, setFetching] = useState(false);

  const pageCount = 10;

  const classes = useStyles();

  const handleBackButtonClick = (event) => {
    setPage(page - 1);
  };

  const handleNextButtonClick = (event) => {
    setPage(page + 1);
  };

  useEffect(() => {
    const fetchRecebiveis = async() => {

    setFetching(true);

    const response = await getRecebiveis(pageCount, pageCount * page);
    if (response.ok) {
      setRows(response.data);
    } else {
      setMensagem("Erro ao buscar os recebíveis. Contate o Administrador");
      setRows([]);
    }

    setFetching(false);
  }

  fetchRecebiveis();

  }, [page, setMensagem]);

  const ResponsiveValue = ({ label, value }) => <div className={classes.value}>
    {isWidthDown('sm', width) && <span className={classes.label}>{label}</span>}
    {value}
  </div>

  return (
    <Fetching isFetching={fetching}>
      <Grid container className={classes.titleContainer}>
        <span className={classes.title}>{"Recebíveis"}</span>
      </Grid>
      <Paper className={classes.paper}>
        {isWidthUp('md', width) && <Grid container className={classes.row}>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Data"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Cliente"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Código"}</span>
          </Grid>
          <Grid item md={1} className={classes.item}>
            <span className={classes.label}>{"Tipo"}</span>
          </Grid>
          <Grid item md={1} className={classes.item}>
            <span className={classes.label}>{"Valor"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Data Pagamento"}</span>
          </Grid>
          <Grid item md={2} className={classes.item}>
            <span className={classes.label}>{"Status"}</span>
          </Grid>        
        </Grid>}
        {rows.map((row, index) => (
          <Grid container key={row.codigoAgendamento + row.tipo} className={index % 2 === 0 ? classes.rowOdd : classes.row}>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Data: "
                value={row.data}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Cliente: "
                value={row.nomeCliente}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Código: "
                value={row.codigoAgendamento}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1} className={classes.item}>
              <ResponsiveValue 
                label="Tipo: "
                value={row.tipo}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1} className={classes.item}>
              <ResponsiveValue 
                label="Valor: "
                value={currencyFormatter(row.valor)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Data Pagamento: "
                value={row.dataPagamento}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.item}>
              <ResponsiveValue 
                label="Status: "
                value={row.status}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container className={classes.arrowsContainer}>           
          <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={rows.length < pageCount}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
      </Paper>
    </Fetching>
  );
}

export default withWidth()(Recebiveis)