import React, { useContext, useState } from 'react'
import {
  Switch, useHistory
} from 'react-router-dom'
import { AppWrap } from "./../Components/AppWrap"
import { Route } from 'react-router-dom'
import Home from '../modules/home/Home';
import logo from '../img/logo_h.png'
import logoXs from '../img/logo_xs.png'
import { createMuiTheme, isWidthUp, MenuItem, MenuList, Popover, ThemeProvider, withWidth } from '@material-ui/core';
import { AuthContext } from '../Components/Login/Auth';
import Configuracoes from '../modules/configuracoes/Configuracoes';
import { VerifyLogin } from "../modules/login";
import { defaultColors, defaultPrimaryColor, defaultSecondaryColor } from '../Theme';
import Saldos from '../modules/saldos/Saldos';
import Agendamentos from '../modules/agendamentos/Agendamentos';


const Logo = withWidth()((props) => ( 
  <img 
    src={isWidthUp('md', props.width) ? logo : logoXs}
    alt="Logo Reservei Online"
    style={{ height: 40 }} 
  />
))


export const AppLoggedRouter = () => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const history = useHistory();
  const authContext = useContext(AuthContext);

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setMenuOpen(false)
    setAnchorEl(null)
  }

  const { primaryColor, secondaryColor, playtimeId } = authContext.user;

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: primaryColor || defaultPrimaryColor
      },
      secondary: {
        main: secondaryColor || defaultSecondaryColor
      },
      ...defaultColors
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <AppWrap
        logo={<Logo />}
        userAvatarProps={{
          onAvatarClick: event => {
            handleClickPopover(event);
            setMenuOpen(true);
          }
        }}>

        <Popover
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuList dense={true}>
            {!playtimeId &&
              <MenuItem
                onClick={() => {
                  history.push('/home')
                  setMenuOpen(false)
                }}
              >
                {"Home"}
              </MenuItem>}
            {!playtimeId &&
              <MenuItem
                onClick={() => {
                  history.push('/saldos')
                  setMenuOpen(false)
                }}
              >
                {"Saldos"}
              </MenuItem>}
            {!playtimeId &&
              <MenuItem
                onClick={() => {
                  history.push('/relatorio')
                  setMenuOpen(false)
                }}
              >
                {"Relatório Agendamentos"}
              </MenuItem>}
            {!playtimeId &&
              <MenuItem
                onClick={() => {
                  history.push('/configuracoes')
                  setMenuOpen(false)
                }}
              >
                {"Configurações"}
              </MenuItem>}
            <MenuItem
              onClick={() => {
                authContext.logout && authContext.logout()
                setMenuOpen(false)
              }}
            >
              {"Sair"}
            </MenuItem>
          </MenuList>
        </Popover>

        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/saldos" exact component={Saldos} />
          <Route path="/configuracoes" exact component={Configuracoes} />
          <Route path="/relatorio" exact component={Agendamentos} />
        </Switch>
        <VerifyLogin />
      </AppWrap>
    </ThemeProvider>
  )
}

