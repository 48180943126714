import { Grid, TextField as MUITextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import Fetching from '../utils/Fetching';
import { buscaCep } from './ReservaApi';
import { MaskedField, TextField } from '../../Components/Fields';
import { Checkbox as CheckboxField } from '../../Components/Common'
import { Field, Form } from 'react-final-form';
import { validateFields } from './DadosPagamentoValidation';
import NoDDDTelephoneField from '../../Components/Fields/NoDDDTelephoneField';

export default (props) => {  

  const { 
    classes, 
    handleSaveReserva, 
    componentConsentimento, 
    componentConfirmarReserva, 
    formRef,
    logged,
    initialValues
  } = props;

  
  const [fetchingCep, setFetchingCep] = useState(false);
  const [showDetailFields, setShowDetailFields] = useState(false);

  const detailCepRef = useRef();

  useEffect(() => {
    if (showDetailFields) {
      detailCepRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showDetailFields]);

  function setValue([field, value], state, { changeValue }) {
    changeValue(state, field, () => value)
  }

  const onChangeCep = (cep, form) => {
    if (cep) {
      const numeroCep = cep.replace(/\D/g,'');
      if (numeroCep.length === 8) {
        setFetchingCep(true);
        buscaCep(numeroCep).then(response => {
          if (response.ok) {
            const { data } = response;
            form.mutators.setValue("cep", cep);
            form.mutators.setValue("logradouro", data.street);
            form.mutators.setValue("bairro", data.neighborhood);
            form.mutators.setValue("cidade", data.city);
            form.mutators.setValue("estado", data.state);
          }
          setFetchingCep(false);
          setShowDetailFields(true);
        }
        );
      }    
    }
  }

  return <>
    <Form
      onSubmit={handleSaveReserva}
      validate={validateFields}
      mutators={{setValue}}
      initialValues={initialValues}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container className={classes.formContainer} ref={formRef}>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                className={classes.field}
                name="numero_cartao"
                label="Número do Cartão"
                placeholder="0000 0000 0000 0000"
                mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                component={MaskedField}
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="nome_cartao"
                className={classes.field}
                label="Nome exibido no cartão"
                component={TextField}
              />
            </Grid>
            <Grid item xs={6} className={classes.fieldContainer}>
              <Field
                name="data_expiracao"
                className={classes.field}
                label="Expiração"
                placeholder="MM/AA"
                mask={[/\d/, /\d/, '/', /\d/, /\d/]}
                component={MaskedField}
              />
            </Grid>
            <Grid item xs={6} className={classes.fieldContainer}>
              <Field
                name="cvv"
                className={classes.field}
                label="CVV Cartão"
                placeholder="000"
                mask={[/\d/, /\d/, /\d/]}
                component={MaskedField}
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="documento"
                className={classes.field}
                label="CPF"
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                placeholder="000.000.000-00"
                component={MaskedField}
              />
            </Grid>
            <Grid item xs={4} className={classes.fieldContainer}>
              <Field
                name="ddd"
                className={classes.field}
                label="DDD"
                mask={['(', /\d/, /\d/, ')']}
                placeholder="(00)"
                component={MaskedField}
              />
            </Grid>
            <Grid item xs={8} className={classes.fieldContainer}>
              <Field
                name="telefone"
                className={classes.field}
                label="Telefone"
                component={NoDDDTelephoneField}
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="email"
                className={classes.field}
                label="E-mail"
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                name="cep"
                className={classes.field}
                label="CEP"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                placeholder="00000-000"
                component={MaskedField}
                input= {{
                  onChange: (event) => onChangeCep(event.target.value, form)
                }}
              />
            </Grid>
            {showDetailFields && <Fetching isFetching={fetchingCep}>
              <Grid item xs={9} className={classes.fieldContainer} ref={detailCepRef}>
                <Field
                  className={classes.field}
                  name="cidade"
                  label="Cidade"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={3} className={classes.fieldContainer}>
                <Field
                  className={classes.field}
                  name="estado"
                  label="Estado"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} className={classes.fieldContainer}>
                <Field
                  className={classes.field}
                  name="bairro"
                  label="Bairro"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} className={classes.fieldContainer}>
                <Field
                  name="logradouro"
                  className={classes.field}
                  label="Rua"
                  placeholder="Rua, Avenida, etc..."
                  component={TextField}
                />
              </Grid>
              <Grid item xs={4} className={classes.fieldContainer}>
                <Field
                  name="numero"
                  className={classes.field}
                  label="N°"
                  component={TextField}
                />
              </Grid>
              <Grid item xs={8} className={classes.fieldContainer}>
                <Field
                  className={classes.field}
                  name="complemento"
                  label="Complemento"
                  component={TextField}
                />
              </Grid>
            </Fetching>}

            <Grid item xs={12} className={classes.checkBoxContainer}>
              {componentConsentimento}
            </Grid>

            {!logged && <>
              <Grid item xs={12} className={classes.checkBoxContainer}>
                <Field
                  name="criar_conta"
                  type="checkbox"
                  component={CheckboxField}
                  label={"Quero criar um cadastro com meu e-mail"}
                  labelClass={classes.textoCheckbox}
                />
              </Grid>

              {values?.criar_conta && <>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <MUITextField 
                    value={values.email}
                    label="Login"
                    style={{width: '100%'}}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Field
                    className={classes.field}
                    component={TextField}
                    label={"Senha"}
                    type='password'
                    name='password'/>
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                  <Field
                    className={classes.field}
                    component={TextField}
                    label={"Confirme a Senha"}
                    type='password'
                    name='password_confirm'/>
                </Grid>
              </>}
            </>}

            <Grid item xs={12} className={classes.submitContainer}>
              {componentConfirmarReserva}
            </Grid>

          </Grid>
        </form>
      )}
    />
  </>;
}