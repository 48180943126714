import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import Saldos from '../saldos/Saldos';
import PlaytimeConfig from './PlaytimeConfig';
import LinkUtilities from './LinkUtilities';

export default () => {
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Paper>
        <LinkUtilities/>
      </Paper>
    </Grid>
    <Grid item xs={12} lg={4}>
      <PlaytimeConfig/>
    </Grid>
    <Grid item  xs={12} lg={8}>
      <Saldos/>
    </Grid>
  </Grid>
}
