import React, { useState } from 'react'
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import logo from '../../img/logo.png'
import Fetching from '../utils/Fetching';
import { cancelaReserva } from './ReservaApi';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1)
  },
  spacedRow: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  logo: {
    width: 200
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 20
  },
  info: {
    color: theme.palette.primary.main,
    fontSize: 14,
    maxWidth: 400,
    textAlign: 'center'
  },
  espaco: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: 14
  },
  digitosInput: {
    width: 44
  },
  emailInput: {
    width: 200
  }
}));

export default (props) => {
  const classes = useStyles();

  const { history } = props;
  const params = new URLSearchParams(history.location.search);
  const codigo = params.get('codigo');
  const tipo = params.get('tipo');

  const [lastDigits, setLastDigits] = useState();
  const [email, setEmail] = useState();
  const [fetching, setFetching] = useState(false);
  const [sucesso, setSucesso] = useState(false);
  const [erro, setErro] = useState();

  const handleCancelar = () => {
    setFetching(true);
    cancelaReserva({ codigo, lastDigits, email: email?.toLowerCase()?.trim() }).then(response => {
      if (response.ok) {
        setErro(undefined)
        setSucesso(true);
      } else {
        setErro(response.data?.message);
      }
      setFetching(false);
    });
  }

  return <Grid container className={classes.root}>
    <Grid item xs={12} className={classes.row}>
      <img 
        src={logo}
        alt="Logo Reservei Online"
        className={classes.logo}
      />
    </Grid>
    <Grid item xs={12} className={classes.row}>
      <span className={classes.title}>{"Cancelamento da Reserva"}</span>
    </Grid>
    <Grid item xs={12} className={classes.row}>
      <span className={classes.info}><strong>Local: </strong>{params.get('nomeEmpresa')}</span>
    </Grid>
    <Grid item xs={12} className={classes.row}>
      <span className={classes.espaco}><strong>Espaço: </strong>{params.get('nomeEspaco')}</span>
    </Grid>
    <Grid item xs={12} className={classes.row}>
      <span className={classes.info}><strong>Horário: </strong>{params.get('horario')}</span>
    </Grid>
    {tipo === 'CARTAO' && <>
      <Grid item xs={12} className={classes.spacedRow}>
        <span className={classes.info}>{"Para confirmar o cancelamento da reserva, informe os últimos 4 digitos do cartão de crédito utilizado para reserva:"}</span>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <TextField 
          className={classes.digitosInput}
          value={lastDigits || ''}
          onChange={(event) => setLastDigits(event.target.value)}
          placeholder="XXXX"
        />
      </Grid>
    </>}
    {tipo === 'PIX' && <>
      <Grid item xs={12} className={classes.spacedRow}>
        <span className={classes.info}>{"Para confirmar o cancelamento da reserva, informe o e-mail informado na reserva:"}</span>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <TextField 
          className={classes.emailInput}
          value={email || ''}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="email@email.com"
        />
      </Grid>
    </>}
    <Grid item xs={12} className={classes.spacedRow}>
      <Fetching isFetching={fetching}>
        <Button
          color="primary"
          disabled={sucesso}
          variant="contained"
          onClick={handleCancelar}
        >
          {"CANCELAR RESERVA"}
        </Button>
      </Fetching>
    </Grid>
    {sucesso && <Grid item xs={12} className={classes.spacedRow}>
      <span className={classes.info}><strong>{"Cancelamento realizado com sucesso. O estorno da cobrança foi enviado para a operadora."}</strong></span>
    </Grid>}
    {erro && <Grid item xs={12} className={classes.spacedRow}>
      <span className={classes.info}><strong>{`Erro ao solicitar cancelamento: ${erro}`}</strong></span>
    </Grid>}
  </Grid>
}