import React, { Component } from 'react'
import FormGroup from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import { Typography } from '@material-ui/core';

class CheckboxInput extends Component {
  render() {

    const {disabled, input: { checked, onChange, ...input} , label, labelClass} = this.props

    return (
      <FormGroup style={{width: '100%'}}>

        <FormControlLabel
          control={
            <Checkbox
              {...input}
              color='primary'
              onChange={(value) => onChange(value)}
              checked={checked}
              disabled={disabled}/>
          }
          label={<Typography className={labelClass}>{label}</Typography>}
          style={{margin: '0px'}}
        />

      </FormGroup>
    )
  }
}

export default CheckboxInput;
