import React, { useState } from 'react';
import { Button, FormControlLabel, Grid, makeStyles, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment'
import { addOcupacaoEspaco } from './AgendaApi';
import Fetching from '../utils/Fetching';

const useStyles = makeStyles(theme => ({
  modal: {
    background: theme.palette.white,
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '330px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    borderRadius: 20
  },
  container: {
    width: '100%'
  },
  labelGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 11,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  selectText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  field: {
    width: '100%',
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  radioText: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: 'bold'
  },
  radioContainer: {
    justifyContent: 'center'
  }
}));

export default (props) => {

  const classes = useStyles();

  const [dataHora, setDataHora] = useState(moment().startOf('hour'));
  const [espaco, setEspaco] = useState(props.espacoSelecionado);
  const [descricao, setDescricao] = useState();
  const [tipoOcupacao, setTipoOcupacao] = useState("RESERVA_MANUAL");

  const [fetching, setFetching] = useState(false);

  const { versionOcupacoes, setVersionOcupacoes, setErro } = props;

  const handleAddOcupacao = () => {
    setFetching(true);
    addOcupacaoEspaco(tipoOcupacao, espaco, dataHora, descricao).then(response => {
      if (response.ok) {
        setVersionOcupacoes(versionOcupacoes + 1);
      } else {
        setErro("Não foi possível adicionar a ocupação. Contate o Administrador");
      }
    })    
  }
  
  return <Modal
    open={props.modalOpen}
    onClose={() => props.setModalOpen(false)}
  >
    <div className={classes.modal}>
      <Fetching isFetching={fetching}> 
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <RadioGroup
              row
              className={classes.radioContainer}
              value={tipoOcupacao}
              onChange={(event) => setTipoOcupacao(event.target.value)}
            >
              <FormControlLabel
                value="RESERVA_MANUAL"
                label={<Typography className={classes.radioText}>{"Reserva Manual"}</Typography>}
                control={<Radio size="small"/>}
                className={classes.radioText}
              />
              <FormControlLabel
                value="INDISPONIVEL"
                label={<Typography className={classes.radioText}>{"Espaço Ocupado"}</Typography>}
                control={<Radio size="small"/>}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"ESPAÇO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <Select
              value={espaco}
              onChange={(event) => setEspaco(event.target.value)}
              className={classes.field}
            >
              {props.espacos && props.espacos.map(q => (
                <MenuItem value={q.id} key={q.id} className={classes.selectText}>
                  {q.nome}
                </MenuItem>))}
            </Select>
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"DATA E HORA"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <DateTimePicker
              value={dataHora}
              onChange={setDataHora}
              format="DD/MM/YYYY HH:mm"
              minutesStep={30}
              ampm={false}
              autoOk
              views={['date', 'hours', 'minutes']}
              className={classes.field}
            />
          </Grid>
          <Grid item xs={4} className={classes.labelGrid}>
            <span>{"DESCRIÇÃO"}</span>
          </Grid>
          <Grid item xs={7} className={classes.center}>
            <TextField
              value={descricao}
              onChange={(event) => setDescricao(event.target.value)}
              className={classes.field}
              placeholder="(opcional)"
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleAddOcupacao}
            >
              {"SALVAR"}
            </Button>
          </Grid>
        </Grid>
      </Fetching>
    </div>
  </Modal>
}