import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Paper } from "@material-ui/core";
import Fetching from '../utils/Fetching';
import { getEmpresa, updateEmpresa } from '../configuracoes/ConfiguracoesApi';
import Snackbar from '../../Components/Common/Snackbar';
import { AuthContext } from '../../Components/Login/Auth';
import { Field, Form } from 'react-final-form';
import ImagePicker from '../../Components/Common/ImagePicker';
import withoutLogo from '../../img/no_logo.png';
import { defaultPrimaryColor, defaultSecondaryColor } from '../../Theme';
import ColorPicker from '../configuracoes/ColorPicker';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  titulo: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2)
  },
  helper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    fontSize: 10,
    color: theme.palette.secondary.main
  },
  field: {
    minWidth: 300
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  buttonSalvar: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: theme.palette.white
  },
  colorPicker: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}));

export default () => {

  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const empresaId = authContext.user.id;
  const [dadosEmpresa, setDadosEmpresa] = useState({});
  const [version, setVersion] = useState(0);

  const [fetching, setFetching] = useState(false);
  const [mensagem, setMensagem] = useState();

  const [primaryColor, setPrimaryColor] = useState();
  const [secondaryColor, setSecondaryColor] = useState();

  const limpaMensagem = () => {
    setMensagem(undefined);
  }

  useEffect(() => {
    const fetchDadosEmpresa = async() => {

      setFetching(true);

      const response = await getEmpresa(empresaId);
      if (response.ok) {
        const { data } = response;
        setDadosEmpresa(data);
        setPrimaryColor(data.primaryColor || defaultPrimaryColor);
        setSecondaryColor(data.secondaryColor || defaultSecondaryColor);
      } else {
        setMensagem("Erro ao buscar dados da academia. Contate o Administrador");
        setDadosEmpresa({});
      }

      setFetching(false);
    }

    fetchDadosEmpresa();
  }, [empresaId, version]);

  const onSubmit = (values) => {

    updateEmpresa(empresaId, {
      file: values.logoUrl && values.logoUrl.length > 0 ? values.logoUrl[0].file : undefined,
      primaryColor,
      secondaryColor,
    }).then(response => {
      if (response.ok) {
        setVersion(version + 1);
        setMensagem("Dados atualizados!");
      } else {
        setMensagem(`Erro ao atualizar: ${response.data.message}`);
      }
    });
  }

  return <>
    <Paper>
      <Fetching isFetching={fetching} >
        <Form
          onSubmit={onSubmit}
          initialValues={dadosEmpresa}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>   
              <Grid container className={classes.form}>   
                <Grid item xs={12} className={classes.row}>
                  <span className={classes.titulo}>{"Logo"}</span>
                </Grid>
                <Grid item xs={12} className={classes.row}>
                  <Field
                    name="logoUrl"
                    defaultImage={withoutLogo}
                    size={300}
                    component={ImagePicker}
                  />
                </Grid>
                <Grid item xs={12} className={classes.helper}>
                  {"O logo deve ser QUADRADO para exibição no App"}
                </Grid>
                <Grid item xs={12} className={classes.row}>
                  <span className={classes.titulo}>{"Cores"}</span>
                </Grid>
                <Grid item xs={12} className={classes.row}>
                  <ColorPicker
                    color={primaryColor}
                    className={classes.colorPicker}
                    onChangeComplete={(color) => setPrimaryColor(color.hex)}
                  />
                  <ColorPicker
                    color={secondaryColor}
                    className={classes.colorPicker}
                    onChangeComplete={(color) => setSecondaryColor(color.hex)}
                  />
                </Grid>
                
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button 
                    type="submit"
                    className={classes.buttonSalvar}
                    color="primary"
                    variant="contained"
                  >
                    {"SALVAR"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Fetching>
    </Paper>
    <Snackbar message={mensagem} onClose={limpaMensagem} />
  </>
}