import React, { useEffect, useState } from 'react';
import Fetching from '../utils/Fetching';
import DadosPagamento from './DadosPagamento';
import { getDadosCliente } from './ReservaApi';
import ReservaLogado from './ReservaLogado';

export default (props) => {

  const [fetchingDadosPagamento, setFetchingDadosPagamento] = useState(false);
  const [dadosPagamento, setDadosPagamento] = useState();

  const { 
    setErro, 
    classes, 
    componentConfirmarReserva, 
    componentConsentimento,
    handleReservaNovosDados,
    handleReservaDadosSalvos,
    logged,
    initialValues
  } = props;

  useEffect(() => {
    const fetchDadosPagamento = async() => {

      setFetchingDadosPagamento(true);

      const response = await getDadosCliente();
      if (response.ok && response.data.length > 0) {
        setDadosPagamento(response.data[0]);
      } else {
        setDadosPagamento(undefined);
      }

      setFetchingDadosPagamento(false);
    }

    fetchDadosPagamento();
      
  }, [setErro]);

  return <Fetching isFetching={fetchingDadosPagamento}>
    {logged && dadosPagamento 
      ? <ReservaLogado
          classes={classes}
          handleSaveReserva={handleReservaDadosSalvos}
          componentConsentimento={componentConsentimento}
          componentConfirmarReserva={componentConfirmarReserva}
          dadosPagamento={dadosPagamento}
        />
      : <DadosPagamento 
          classes={classes}
          handleSaveReserva={handleReservaNovosDados}
          componentConsentimento={componentConsentimento}
          componentConfirmarReserva={componentConfirmarReserva}
          logged={logged}
          initialValues={initialValues}
        />}
  </Fetching>
}