import React from 'react';
import { SketchPicker } from "react-color";

export default (props) => {
  return <SketchPicker
    width={140}
    presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2']}
    disableAlpha
    {...props}
  />;
};